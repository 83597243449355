var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sessions-component"},[_c('Breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('Widget',[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"value":_vm.getSessions,"paginator":true,"filters":_vm.filters,"loading":_vm.loading,"rows":100,"dataKey":"number","rowHover":true,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","currentPageReportTemplate":_vm.$t('show') +
          ' {first} ' +
          _vm.$t('to') +
          ' {last} ' +
          _vm.$t('of') +
          ' {totalRecords} ' +
          _vm.$t('records')},on:{"row-edit-init":_vm.onRowEditInit,"row-edit-cancel":_vm.onRowEditCancel,"row-edit-save":_vm.onRowEditSave},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-header-left"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t('sessions')))])]),_c('div',{staticClass:"table-header-right"},[_c('div',{staticClass:"button-right"},[_c('router-link',{staticClass:"btn btn-success mb-2 right",attrs:{"to":{ name: 'SessionCreatePage' }}},[_c('i',{staticClass:"fa fa-plus-circle mr-2"}),_vm._v(" "+_vm._s(_vm.$t('createSession'))+" ")])],1)])])]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('no_data_found'))+" ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]},proxy:true}])},[_c('Column',{attrs:{"field":"number","header":"#","sortable":"","showFilterMenu":false,"headerStyle":{ width: '40px' },"bodyStyle":{ 'text-align': 'left' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.number === '_new')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('_new'))+" ")]):_c('span',[_c('router-link',{attrs:{"to":{
                name: 'SessionEditPage',
                params: { sessionNumber: slotProps.data.number },
              }}},[_vm._v(_vm._s(slotProps.data.number.toString().padStart(4, '0')))])],1)]}},{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},model:{value:(_vm.filters['number']),callback:function ($$v) {_vm.$set(_vm.filters, 'number', $$v)},expression:"filters['number']"}})]},proxy:true}])}),_c('Column',{attrs:{"field":"name","header":_vm.$t('name'),"sortable":"","styles":{ width: '50%' },"bodyStyle":{ 'text-align': 'left' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.data.name)}})]}},{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},model:{value:(_vm.filters['name']),callback:function ($$v) {_vm.$set(_vm.filters, 'name', $$v)},expression:"filters['name']"}})]},proxy:true}])}),_c('Column',{attrs:{"field":"description","header":_vm.$t('description'),"sortable":"","styles":{ width: '50%' },"bodyStyle":{ 'text-align': 'left' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.data.description)}})]}},{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},model:{value:(_vm.filters['description']),callback:function ($$v) {_vm.$set(_vm.filters, 'description', $$v)},expression:"filters['description']"}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }